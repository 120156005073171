import React, { memo, ReactNode, useCallback, useMemo, useRef } from 'react';
import { StyledBackdrop, StyledContent, StyledDrawer, StyledDrawerContainer } from './styled';

type DrawerProps = {
    open: boolean;
    children: ReactNode;
    onClose?: () => void;
    isInfo?: boolean;
    enableVerticalSlideMobile?: boolean;
};

export const Drawer = memo(
    ({ children, open, onClose, isInfo, enableVerticalSlideMobile }: DrawerProps) => {
        const previousFocus = useRef<HTMLElement | null>();

        /**
         * Focus on the previous element when the drawer closes.
         * Using useMemo instead of useEffect to ensure previous focus
         * is captured before the first menu item is focused.
         */
        useMemo(() => {
            const previousElement = previousFocus.current;

            if (open) {
                previousFocus.current = document.activeElement as HTMLElement;
            } else if (!open && previousElement) {
                previousElement.focus();
            }
        }, [open]);

        const onKeyDownHandler = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.key === 'Escape') {
                onClose?.();
            }
        }, []);

        return (
            <StyledDrawerContainer open={open} onKeyDown={onKeyDownHandler}>
                <StyledDrawer
                    open={open}
                    isInfo={isInfo}
                    enableVerticalSlideMobile={enableVerticalSlideMobile}
                >
                    <StyledContent children={children} />
                </StyledDrawer>
                <StyledBackdrop
                    open={open}
                    onClick={onClose}
                    isInfo={isInfo}
                    enableVerticalSlideMobile={enableVerticalSlideMobile}
                />
            </StyledDrawerContainer>
        );
    }
);
